import React from "react";

import "./Logo.css";

export default function Logo(props) {
  return (
    <svg
      className="b2bninja-logo"
      width={180}
      viewBox="0 0 1380 740"
      {...props}
    >
      <g transform="matrix(6.5189 0 0 6.5189 264.87 9.9348)">
        <path
          class="cls-1"
          transform="translate(-191.71,-361.65)"
          d="m253.63 398.77c1.51 5-4.74 11.17-14 10.38s-14.32-8-12-12.65 8.26-6.66 13.62-6.28c5.4 0.56 10.92 3.49 12.38 8.55z"
        />
        <path
          class="cls-1"
          transform="translate(-191.71,-361.65)"
          d="m265.67 398.77c-1.51 5 4.74 11.17 14 10.38s14.32-8 12-12.65-8.27-6.66-13.62-6.28c-5.39 0.56-10.91 3.49-12.38 8.55z"
        />
        <path
          class="cls-1"
          transform="translate(-191.71,-361.65)"
          d="m291.1 379.9a21 21 0 0 1 14.95 20.1 21 21 0 0 1-21 21h-63.81l-29.53 17.36h130.43v-76.7z"
        />
      </g>
      <g transform="matrix(4.7328 0 0 4.7328 -687.97 364.19)">
        <path
          class="cls-1"
          transform="translate(-191.71,-361.65)"
          d="m340.3 401.54h18.87c4.63 0 7.89 1.16 10 3.32a8 8 0 0 1 2.37 5.94v0.1c0 4.26-2.42 6.79-5.68 8.21 4.47 1.47 7.31 4 7.31 9v0.1c0 6.31-5.15 10.15-13.93 10.15h-19zm16.35 14.67c3.1 0 4.84-1 4.84-3.2v-0.11c0-2-1.58-3.15-4.63-3.15h-6.63v6.46zm1.47 13.94c3.1 0 4.89-1.21 4.89-3.37v-0.1c0-2-1.58-3.32-5-3.32h-7.78v6.79z"
        />
        <path
          class="cls-1"
          transform="translate(-191.71,-361.65)"
          d="m376.36 430.67 13.78-10.51c3.42-2.68 4.73-4.42 4.73-6.63s-1.63-3.73-4.1-3.73-4.42 1.42-7.31 4.73l-6.89-5.73c3.95-5.1 7.84-7.89 15-7.89 8 0 13.57 4.79 13.57 11.73v0.1c0 5.89-3.05 8.94-8.52 12.89l-6.32 4.37h15.19v8.31h-29.13z"
        />
        <path
          class="cls-1"
          transform="translate(-191.71,-361.65)"
          d="m410.7 401.54h18.87c4.63 0 7.89 1.16 10.05 3.32a8.06 8.06 0 0 1 2.38 5.94v0.1c0 4.26-2.42 6.79-5.68 8.21 4.47 1.47 7.31 4 7.31 9v0.1c0 6.31-5.15 10.15-13.93 10.15h-19zm16.35 14.67c3.1 0 4.84-1 4.84-3.2v-0.11c0-2-1.58-3.15-4.63-3.15h-6.62v6.46zm1.47 13.94c3.1 0 4.89-1.21 4.89-3.37v-0.1c0-2-1.57-3.32-5-3.32h-7.78v6.79z"
        />
        <path
          class="cls-1"
          transform="translate(-191.71,-361.65)"
          d="m483.81 403.5h3.78v34.36h-3.1l-22.18-28.18v28.18h-3.78v-34.36h3.63l21.65 27.5z"
        />
        <path
          class="cls-1"
          transform="translate(-191.71,-361.65)"
          d="m501.53 403.5v34.36h-3.88v-34.36z"
        />
        <path
          class="cls-1"
          transform="translate(-191.71,-361.65)"
          d="m536.87 403.5h3.78v34.36h-3.1l-22.18-28.18v28.18h-3.78v-34.36h3.63l21.65 27.5z"
        />
        <path
          class="cls-1"
          transform="translate(-191.71,-361.65)"
          d="m567.48 431.65a10.5 10.5 0 0 1-2.21 3.73 8.76 8.76 0 0 1-3.37 2.23 12.08 12.08 0 0 1-4.27 0.74 11.22 11.22 0 0 1-6.43-1.72 13.93 13.93 0 0 1-4.17-4.37l2.8-2.4a12.89 12.89 0 0 0 3.36 3.65 7.6 7.6 0 0 0 4.49 1.26 6.26 6.26 0 0 0 4.81-2.09q1.92-2.08 1.92-6.16v-23h3.87v23a15.2 15.2 0 0 1-0.8 5.13z"
        />
        <path
          class="cls-1"
          transform="translate(-191.71,-361.65)"
          d="m608.29 437.86h-4.17l-4-9.08h-18.7l-4.08 9.08h-4l15.66-34.61h3.63zm-17.53-30-7.85 17.52h15.66z"
        />
        <text class="cls-1" transform="translate(417.02,48.59)">
          TM
        </text>
      </g>
    </svg>
  );
}
