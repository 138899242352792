/* @jsx jsx */
import { useState, useRef, Fragment } from "react";
import { jsx, css } from "@emotion/core";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import TableOfContents from "../Docs/TOC";
import Sidebar from "@rocketseat/gatsby-theme-docs/src/components/Sidebar";
import Header from "@rocketseat/gatsby-theme-docs/src/components/Header";
import Overlay from "@rocketseat/gatsby-theme-docs/src/components/Overlay";
import {
  Container,
  Main,
  Children,
} from "@rocketseat/gatsby-theme-docs/src/components/Layout/styles";

export default function Layout({
  children,
  disableTableOfContents,
  title,
  headings,
}) {
  const contentRef = useRef(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const disableTOC =
    disableTableOfContents === true || !headings || headings.length === 0;

  function handleMenuOpen() {
    setMenuOpen(!isMenuOpen);
  }

  return (
    <Fragment>
      <Overlay isMenuOpen={isMenuOpen} onClick={handleMenuOpen} />
      <Container>
        <Sidebar isMenuOpen={isMenuOpen} />
        <Main>
          <Header handleMenuOpen={handleMenuOpen} />
          {title && (
            <h1
              css={css`
                display: none;

                @media (max-width: 1200px) {
                  display: block;
                }
              `}
            >
              {title}
            </h1>
          )}
          <Children
            ref={contentRef}
            css={
              disableTOC
                ? css`
                    max-width: unset !important;
                  `
                : undefined
            }
          >
            {title && (
              <h1
                css={css`
                  @media (max-width: 1200px) {
                    display: none;
                  }
                `}
              >
                {title}
              </h1>
            )}
            {children}
          </Children>
          <TableOfContents
            headings={headings}
            disableTOC={disableTOC}
            contentRef={contentRef}
          />
        </Main>
      </Container>
      <Helmet>
        <script>
          {`window.SWU_CONFIG = {
            "style": "blue",
            "placement": "bottom-right",
            "charity_selections": ["razom", "mira-action", "new-ukraine"],
            "modal_title": "Help the people of Ukraine!",
            "modal_body": "With each day, the war in Ukraine worsens at an alarming pace. Millions of civilians have lost their homes and many more are without basic necessities like food, water, and health care. Consider donating to one of the charities below and join us in showing support for Ukraine. All charities are trusted, non-profit organizations dedicated to Ukrainian relief efforts. It takes less than a minute."
          };`}
        </script>
        <script
          defer
          src="https://stand-with-ukraine-bc-app.web.app/widget/index.js"
        ></script>
      </Helmet>
    </Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disableTableOfContents: PropTypes.bool,
  title: PropTypes.string,
  headings: PropTypes.array,
};

Layout.defaultProps = {
  disableTableOfContents: false,
  title: "",
  headings: null,
};
